export default {
  // English
  en: {
    native_name_of_the_language: 'English',
    global: {
      default_title: 'Health Medical Center',
      continue: 'Continue',
      loading_message: 'Loading...',
      no: 'No',
      powered_by: 'Powered by',
      search: 'Search...',
      try_again: 'Try Again',
      yes: 'Yes'
    },
    google: {
      reservation_address_notification: 'You are scheduling an appointment at {googleReservationAddress}.'
    },
    self_scheduling: {
      inbound_negative_conditional: 'Sorry, we are unable to schedule this booking. Please call our office directly to schedule this appointment: {phone}. Thank you!'
    },
    time: {
      hours: '1 hour | {hours} hours',
      minutes: '1 minute | {minutes} minutes'
    },
    wizard: {
      choose_state: 'Select state',
      choose_appointment_type: 'Choose an appointment type',
      no_available_appointment_types: "Sorry, we currently don't have appointments available",
      no_available_appointment_types_by_state: "Sorry, we currently don't have appointments available in your state",
      go_back: 'Go back',
      warning_message: 'Due to high demand, it will take up to 90 secs to find available slots, please be patient.',
      choose_doctor: 'Choose a provider',
      no_available_providers: "Sorry, we currently don't have providers attending this type of appointments",
      no_available_providers_by_state: "Sorry, we currently don't have providers attending this type of appointments on your state",
      any_provider: 'Any provider',
      choose_date: 'Select date',
      choose_time: 'Select time',
      dropdown_default: 'Choose an option',
      complete_your_schedule: 'Complete your schedule',
      form_header_message: 'Almost done!',
      form_field_referrer_email: 'Referrer email',
      form_field_referrer_first_name: 'Referrer first name',
      form_field_referrer_last_name: 'Referrer last name',
      form_field_referrer_address: 'Location',
      form_field_first_name: 'First name',
      form_field_last_name: 'Last name',
      form_field_cellphone: 'Cellphone',
      form_field_email: 'Email',
      form_field_ssn: 'I don’t have a valid insurance policy (per CARES Act Provider Relief Fund we will request a Social Security Number to verify your insurance status).',
      form_field_label_ssn: 'SSN*',
      form_field_visit_reason: 'Visit reason',
      form_field_placeholder_optional: 'Optional',
      form_field_placeholder_optional_recommended: 'Optional, but highly recommended',
      form_field_birthday: 'Date of birth',
      form_field_birthday_day: 'Day',
      form_field_birthday_month: 'Month',
      form_field_birthday_year: 'Year',
      form_field_legal_sex: 'Legal sex',
      form_field_legal_sex_male: 'Male',
      form_field_legal_sex_female: 'Female',
      form_field_legal_sex_other: 'Other',
      form_field_address: 'Address',
      form_field_address_placeholder: 'Street and number',
      form_field_zip_code: 'Zip Code',
      form_field_city: 'City',
      form_field_state: 'State',
      form_field_state_warning: 'We currently provide services only to patients in the states listed.',
      form_field_image: 'Upload photos of your insurance card',
      form_field_card: 'Select the option of your preference',
      form_field_insurance: 'I have insurance',
      form_field_image_one: 'Front',
      form_field_image_two: 'Back',
      form_field_image_error_one: 'Image size must be equal or less than 10MB.',
      form_field_image_error_two: 'File format incorrect. (.png , .jpg and .jpeg).',
      form_field_image_error_three: 'This field cannot be empty.',
      form_field_insurance_company: 'Health Insurance',
      form_field_insurance_group_number: 'Group ID',
      form_field_insurance_id_number: 'Member ID',
      form_field_insurance_plan_type: 'Plan type',
      form_label_terms_and_conditions_message: 'I agree to the ',
      form_label_terms_and_conditions_link: 'Terms and conditions',
      form_label_terms_and_conditions_telehealth_link: 'Telehealth Informed Consent',
      form_label_consent_to_text: 'Do you consent to receive details about your appointment, including assistance with confirmations, rescheduling, and necessary payment instructions via text?',
      form_submit_button: 'Schedule appointment',
      confirmed_message: 'Confirmed',
      details_message: 'Details',
      reserved_message: 'Reserved',
      invitation_sent_message: 'The invitation has been sent to your email address',
      invitation_sent_message_referrals: 'A confirmation email was sent to you and your patient at the email addresses provided',
      require_payment_information_message: 'Please add your payment information.',
      no_charge_will_be_done_message: 'No charge will be made until review by the practice',
      lead_time_require_payment_information_message: 'Payment information is needed to hold your appointment.',
      lead_time_to_finish_message: 'You have {leadTime} to finish.',
      payment_message: 'Your appointment is ready!',
      suggest_payment_message: 'Click below if you’d like to pay online',
      lead_time_payment_message: 'You have {leadTime} to finish your payment.',
      for_questions_message: 'For questions, please call the office',
      complete_information: 'Complete Information',
      continue_to_pay: 'Continue to pay',
      pay_now: 'Pay now',
      referrals_patient_information: 'Patient\'s Information'
    },
    calendar: {
      day_name_sun: 'SUN',
      day_name_mon: 'MON',
      day_name_tue: 'TUE',
      day_name_wed: 'WED',
      day_name_thu: 'THU',
      day_name_fri: 'FRI',
      day_name_sat: 'SAT',
      month_name_1: 'January',
      month_name_2: 'February',
      month_name_3: 'March',
      month_name_4: 'April',
      month_name_5: 'May',
      month_name_6: 'June',
      month_name_7: 'July',
      month_name_8: 'August',
      month_name_9: 'September',
      month_name_10: 'October',
      month_name_11: 'November',
      month_name_12: 'December'
    },
    errors: {
      hashtag_not_found: 'The account id doesn\'t exist',
      server_error: 'A server error occurred',
      no_available_dates_title: 'Sorry, there are no appointments available at this time.',
      no_available_dates_body: 'Please try another provider.',
      slots_not_found: 'Sorry, appointment times on this day are not available. Please select another day',
      scheduling: {
        contacts_title: 'Oops! There Was a Glitch',
        contacts_body: 'Please verify your details and give it another shot. If you still face issues, don\'t hesitate to contact the office at {officePhone}.',
        events_title: 'Oops, Something Went Awry',
        events_body: 'Let\'s try again. Please make sure your information is correct. If the problem continues, your office is ready to assist. Call them at {officePhone}.',
        failed_dependency_title: 'We are sorry, we were unable to schedule your appointment.',
        failed_dependency_body: 'Please contact the office to schedule your appointment {officePhone}',
        gateway_timeout_title: 'Taking a Bit Longer Than Usual',
        gateway_timeout_body: 'We\'re still processing your appointment. Please contact your office at {officePhone}, to verify the status of your appointment.',
        general_title: 'Ah, That Didn\'t Go as Planned',
        general_body: 'Let\'s give it another go. Please select another slot and try to schedule your appointment again.',
        slot_already_taken_title: 'Oops! Someone else has taken this slot. Don’t worry… There are plenty more options!',
        slot_already_taken_body: 'Please choose another time.'
      }
    }
  },
  // Spanish
  es: {
    native_name_of_the_language: 'Español',
    global: {
      default_title: 'Centro Médico de Salud',
      continue: 'Continuar',
      loading_message: 'Cargando...',
      no: 'No',
      powered_by: 'Desarrollado por',
      search: 'Buscar...',
      try_again: 'Reintentar',
      yes: 'Si'
    },
    google: {
      reservation_address_notification: 'Estás programando una cita en {googleReservationAddress}.'
    },
    self_scheduling: {
      inbound_negative_conditional: 'Lo sentimos, no puedo agendar tu cita. \nPor favor llama directamente a la oficina para agendar tu cita: {phone}. Gracias!'
    },
    time: {
      hours: '1 hora | {hours} horas',
      minutes: '1 min | {minutes} min'
    },
    wizard: {
      choose_state: 'Selecciona tu estado',
      choose_appointment_type: 'Selecciona un tipo de cita',
      no_available_appointment_types: 'Lo sentimos, actualmente no tenemos citas disponibles',
      no_available_appointment_types_by_state: 'Lo sentimos, actualmente no tenemos citas disponibles en su estado',
      go_back: 'Regresar',
      warning_message: 'Debido a la alta demanda, se necesitarán hasta 90 segundos para encontrar los espacios disponibles, tenga paciencia.',
      choose_doctor: 'Selecciona un proveedor',
      no_available_providers: 'Lo sentimos, actualmente no tenemos proveedores que atiendan este tipo de citas',
      no_available_providers_by_state: 'Lo sentimos, actualmente no tenemos proveedores que atiendan este tipo de citas en su estado',
      any_provider: 'Cualquier proveedor',
      choose_date: 'Selecciona el día',
      choose_time: 'Elige la hora',
      dropdown_default: 'Elige una opción',
      complete_your_schedule: 'Completa tu agendamiento',
      form_header_message: '¡Casi terminamos!',
      form_field_referrer_email: 'Correo electrónico del doctor referente',
      form_field_referrer_first_name: 'Nombre del doctor referente',
      form_field_referrer_last_name: 'Apellido del doctor referente',
      form_field_referrer_address: 'Locación',
      form_field_first_name: 'Nombre',
      form_field_last_name: 'Apellido(s)',
      form_field_cellphone: 'Número de celular',
      form_field_email: 'Correo electrónico',
      form_field_ssn: 'No tengo una póliza de seguro válida (según el Fondo de Ayuda para Proveedores de la Ley CARES, solicitaremos un Número de Seguro Social para verificar el estado de su seguro).',
      form_field_label_ssn: 'SSN*',
      form_field_visit_reason: 'Motivo de la visita',
      form_field_placeholder_optional: 'Opcional',
      form_field_placeholder_optional_recommended: 'Opcional, pero muy recomendado',
      form_field_birthday: 'Fecha de nacimiento',
      form_field_birthday_day: 'Día',
      form_field_birthday_month: 'Mes',
      form_field_birthday_year: 'Año',
      form_field_legal_sex: 'Sexo',
      form_field_legal_sex_male: 'Hombre',
      form_field_legal_sex_female: 'Mujer',
      form_field_legal_sex_other: 'Otro',
      form_field_address: 'Dirección',
      form_field_address_placeholder: 'Calle y número',
      form_field_zip_code: 'Código postal',
      form_field_city: 'Ciudad',
      form_field_state: 'Estado',
      form_field_state_warning: 'Actualmente brindamos servicios solo a pacientes en los estados listados.',
      form_field_image: 'Sube fotos de tu tarjeta de seguro',
      form_field_card: 'Seleccione la opción de su preferencia',
      form_field_insurance: 'Tengo seguro',
      form_field_image_one: 'Frente',
      form_field_image_two: 'Atrás',
      form_field_image_error_one: 'El tamaño de la imagen debe ser igual o menor a 10 MB.',
      form_field_image_error_two: 'Formato de archivo incorrecto. (.png, .jpg y .jpeg).',
      form_field_image_error_three: 'Seleccione una imagen.',
      form_field_insurance_company: 'Seguro de salud',
      form_field_insurance_group_number: 'ID del grupo',
      form_field_insurance_id_number: 'ID del miembro',
      form_field_insurance_plan_type: 'Tipo de plan',
      form_submit_button: 'Agendar cita',
      form_label_terms_and_conditions_message: 'Acepto el ',
      form_label_terms_and_conditions_link: 'Términos y condiciones',
      form_label_terms_and_conditions_telehealth_link: 'Consentimiento Informado de Telesalud',
      form_label_consent_to_text: '¿Acepta recibir detalles sobre su cita, incluida asistencia con confirmaciones, reprogramaciones e instrucciones de pago necesarias por mensaje de texto?',
      confirmed_message: 'Cita confirmada',
      details_message: 'Detalles de la cita',
      reserved_message: 'Reservado',
      invitation_sent_message: '¡Listo!, la invitación ha sido enviada a tu correo electrónico',
      invitation_sent_message_referrals: 'Se le envió un correo electrónico de confirmación a usted y a su paciente a las direcciones de correo electrónico proporcionadas',
      require_payment_information_message: 'Por favor incluya su información de pago.',
      no_charge_will_be_done_message: 'No se realizará ningún cargo en este momento',
      lead_time_require_payment_information_message: 'Es necesario un método de pago para confirmar la cita.',
      lead_time_to_finish_message: 'Tiene {leadTime} para completar la información.',
      payment_message: '¡Listo! Su cita está agendada.',
      suggest_payment_message: 'De click a continuación si desea hacer el pago en línea',
      lead_time_payment_message: 'Tiene {leadTime} para completar su pago.',
      for_questions_message: 'Para dudas, por favor contacte a la oficina',
      complete_information: 'Completar Datos',
      continue_to_pay: 'Realizar pago',
      pay_now: 'Pagar ahora',
      referrals_patient_information: 'Información del paciente'
    },
    calendar: {
      day_name_sun: 'DOM',
      day_name_mon: 'LUN',
      day_name_tue: 'MAR',
      day_name_wed: 'MIE',
      day_name_thu: 'JUE',
      day_name_fri: 'VIE',
      day_name_sat: 'SAB',
      month_name_1: 'Enero',
      month_name_2: 'Febrero',
      month_name_3: 'Marzo',
      month_name_4: 'Abril',
      month_name_5: 'Mayo',
      month_name_6: 'Junio',
      month_name_7: 'Julio',
      month_name_8: 'Agosto',
      month_name_9: 'Septiembre',
      month_name_10: 'Octubre',
      month_name_11: 'Noviembre',
      month_name_12: 'Diciembre'
    },
    errors: {
      hashtag_not_found: 'La cuenta proporcionada no existe',
      server_error: 'Ocurrió un error al procesar la solicitud',
      no_available_dates_title: 'Lo sentimos, no hay citas disponibles en este momento.',
      no_available_dates_body: 'Por favor, intente con otro proveedor.',
      slots_not_found: 'Lo sentimos, los horarios de las citas de este día no están disponibles. Seleccione otro día',
      scheduling: {
        contacts_title: '¡Vaya! Hubo un Problema',
        contacts_body: 'Por favor verifica tus datos e intentarlo una vez más. Si el problema persiste, estamos aquí para ayudarte. No dudes en llamar al {officePhone}.',
        events_title: '¡Ups! Algo está raro',
        events_body: 'Intentemoslo de nuevo. Por favor, asegúrese de que su información sea correcta. Si el problema continúa, su oficina está lista para ayudarlo. Lláma al {officePhone}.',
        failed_dependency_title: 'Lo sentimos, no pudimos agendar su cita.',
        failed_dependency_body: 'Comuníquese con la oficina para agendar su cita {officePhone}',
        gateway_timeout_title: 'Está tomando un poco más de lo usual',
        gateway_timeout_body: 'Seguimos procesando tu cita. Por favor, contacta a tu oficina al {officePhone} para verificar que tu cita haya quedado registrada.',
        general_title: 'Ah, Eso no salió como esperábamos',
        general_body: 'Intentémoslo de nuevo. Por favor, selecciona otra hora y trata de agendar tu cita de nuevo.',
        slot_already_taken_title: '¡Ups! Alguien más ha ocupado este espacio. No te preocupes… ¡Hay muchas más opciones!',
        slot_already_taken_body: 'Por favor elige otro horario.'
      }
    }
  },
  // Portuguese
  pt: {
    native_name_of_the_language: 'Português',
    global: {
      default_title: 'Centro Médico de Saúde',
      continue: 'Continuar',
      loading_message: 'Carregando...',
      no: 'Não',
      powered_by: 'Desenvolvido por',
      search: 'Buscar...',
      try_again: 'Tente novamente',
      yes: 'Sim'
    },
    google: {
      reservation_address_notification: 'Você está agendando uma consulta em {googleReservationAddress}.'
    },
    self_scheduling: {
      inbound_negative_conditional: 'Desculpe, não consigo agendar seu horário. Ligue diretamente para o escritório para agendar sua consulta: {phone}. Obrigado!'
    },
    time: {
      hours: '1 hora | {hours} horas',
      minutes: '1 minuto | {minutes} minutos'
    },
    wizard: {
      choose_state: 'Selecione seu estado',
      choose_appointment_type: 'Selecione um tipo de consulta',
      no_available_appointment_types: 'Desculpe, no momento não temos consulas disponíveis',
      no_available_appointment_types_by_state: 'Desculpe, no momento não temos consultas disponíveis em seu estado',
      go_back: 'Volte',
      warning_message: 'Devido à alta demanda, os horários disponíveis podem demorar até 90 segundos, por favor, espere.',
      choose_doctor: 'Selecione um provedor',
      no_available_providers: 'Desculpe, no momento não temos provedores atendendo este tipo de consultas',
      no_available_providers_by_state: 'Desculpe, no momento não temos provedores atendendo esse tipo de consulta em seu estado',
      any_provider: 'Qualquer provedor',
      choose_date: 'Selecione o dia',
      choose_time: 'Escolha a hora',
      dropdown_default: 'Escolha uma opção',
      complete_your_schedule: 'Complete sua agendamento',
      form_header_message: 'Estamos quase terminando!',
      form_field_referrer_email: 'E-mail do médico de referência',
      form_field_referrer_first_name: 'Nome do médico de referência',
      form_field_referrer_last_name: 'Apelido do médico de referência',
      form_field_referrer_address: 'Localização',
      form_field_first_name: 'Nome',
      form_field_last_name: 'Sobrenome(s)',
      form_field_cellphone: 'Número de telefone celular',
      form_field_email: 'Email',
      form_field_ssn: 'Não tenho um número de beneficiário válido (de acordo com o CARES Act Provider Assistance Fund, solicitaremos um número de beneficiário para verificar o status do seu seguro médico).',
      form_field_label_ssn: 'SSN*',
      form_field_visit_reason: 'O motivo da visita',
      form_field_placeholder_optional: 'Opcional',
      form_field_placeholder_optional_recommended: 'Opcional, mas altamente recomendado',
      form_field_birthday: 'Data de nascimento',
      form_field_birthday_day: 'Dia',
      form_field_birthday_month: 'Mês',
      form_field_birthday_year: 'Ano',
      form_field_legal_sex: 'Sexo',
      form_field_legal_sex_male: 'Masculino',
      form_field_legal_sex_female: 'Feminino',
      form_field_legal_sex_other: 'Outro',
      form_field_address: 'Endereço',
      form_field_address_placeholder: 'Rua e número',
      form_field_zip_code: 'Código postal',
      form_field_city: 'Cidade',
      form_field_state: 'Estado',
      form_field_state_warning: 'Atualmente, fornecemos serviços apenas para pacientes nos estados listados.',
      form_field_image: 'Faça upload de fotos do seu cartão de seguro',
      form_field_card: 'Selecione a opção de sua preferência',
      form_field_insurance: 'Eu tenho seguro',
      form_field_image_one: 'Frente',
      form_field_image_two: 'Verso',
      form_field_image_error_one: 'O tamanho da imagem deve ser igual ou inferior a 10 MB.',
      form_field_image_error_two: 'Formato do arquivo é incorreto. (.png, .jpg e .jpeg).',
      form_field_image_error_three: 'Selecione uma imagem.',
      form_field_insurance_company: 'Seguro de saúde',
      form_field_insurance_group_number: 'ID do grupo',
      form_field_insurance_id_number: 'ID do membro',
      form_field_insurance_plan_type: 'Tipo de plano',
      form_label_terms_and_conditions_message: 'Estou de acordo com ',
      form_label_terms_and_conditions_link: 'Termos e Condições',
      form_label_terms_and_conditions_telehealth_link: 'Consentimento Informado Telessaúde',
      form_label_consent_to_text: 'Você concorda em receber detalhes sobre sua consulta, incluindo assistência com confirmações, reagendamento e instruções de pagamento necessárias por mensagem de texto?',
      form_submit_button: 'Marque uma consulta',
      confirmed_message: 'Consulta confirmada',
      details_message: 'Detalhes',
      reserved_message: 'Reservado',
      invitation_sent_message: 'Pronto! O convite foi enviado para o seu e-mail',
      invitation_sent_message_referrals: 'Um e-mail de confirmação foi enviado para você e seu paciente nos endereços de e-mail fornecidos',
      require_payment_information_message: 'Por favor, adicione suas informações de pagamento.',
      no_charge_will_be_done_message: 'Nenhuma cobrança será feita neste momento',
      lead_time_require_payment_information_message: 'Informações de pagamento são necessárias para manter sua consulta.',
      lead_time_to_finish_message: 'Você tem {leadTime} para completar as informações.',
      payment_message: 'Seu compromisso está pronto!',
      suggest_payment_message: 'Clique abaixo se você gostaria de pagar online',
      lead_time_payment_message: 'Você tem {leadTime} para finalizar o pagamento.',
      for_questions_message: 'Em caso de dúvidas, ligue para o escritório',
      complete_information: 'Dados Completos',
      continue_to_pay: 'Faça o pagamento',
      pay_now: 'Pague agora',
      referrals_patient_information: 'Informações do Paciente'
    },
    calendar: {
      day_name_sun: 'DOM',
      day_name_mon: 'SEG',
      day_name_tue: 'TER',
      day_name_wed: 'QUA',
      day_name_thu: 'QUI',
      day_name_fri: 'SEX',
      day_name_sat: 'SAB',
      month_name_1: 'Janeiro',
      month_name_2: 'Fevereiro',
      month_name_3: 'Março',
      month_name_4: 'Abril',
      month_name_5: 'Maio',
      month_name_6: 'Junho',
      month_name_7: 'Julho',
      month_name_8: 'Agosto',
      month_name_9: 'Setembro',
      month_name_10: 'Outubro',
      month_name_11: 'Novembro',
      month_name_12: 'Dezembro'
    },
    errors: {
      hashtag_not_found: 'A conta fornecida não existe',
      server_error: 'Ocorreu um erro ao processar sua solicitação',
      no_available_dates_title: 'Desculpe, não há horários disponíveis no momento.',
      no_available_dates_body: 'Por favor, tente outro provedor.',
      slots_not_found: 'Desculpe, os horários das consultas de hoje não estão disponíveis. Selecione outro dia',
      scheduling: {
        contacts_title: 'Ops! Houve uma falha',
        contacts_body: 'Verifique seus dados e tente novamente. Se você ainda enfrentar problemas, não hesite em entrar em contato com o escritório em {officePhone}.',
        events_title: 'Ops, algo deu errado',
        events_body: 'Vamos tentar de novo. Verifique se suas informações estão corretas. Se o problema persistir, seu escritório está pronto para ajudar. Ligue para eles em {officePhone}.',
        failed_dependency_title: 'Lamentamos, não foi possível agendar a sua consulta.',
        failed_dependency_body: 'Entre em contato com o escritório para agendar sua consulta {officePhone}',
        gateway_timeout_title: 'Demorando um pouco mais do que o normal',
        gateway_timeout_body: 'Ainda estamos processando seu agendamento. Entre em contato com seu escritório em {officePhone} para verificar o status do seu compromisso.',
        general_title: 'Ah, isso não saiu como planejado',
        general_body: 'Vamos dar outra chance. Selecione outro horário e tente agendar novamente.',
        slot_already_taken_title: 'Ops! Outra pessoa ocupou este slot. Não se preocupe… Há muito mais opções!',
        slot_already_taken_body: 'Por favor, escolha outro horário.'
      }
    }
  },
  // Chinese
  zh: {
    native_name_of_the_language: '中文',
    global: {
      default_title: '健康医疗中心',
      continue: '继续',
      loading_message: '正在加载...',
      no: '不',
      powered_by: '由开发',
      search: '搜索...',
      try_again: '再试一次',
      yes: '是的'
    },
    google: {
      reservation_address_notification: '您正在安排在 {googleReservationAddress} 的约会。'
    },
    self_scheduling: {
      inbound_negative_conditional: '抱歉，我不能安排你的约会。 请直接致电办公室安排您的约会：{phone}。 谢谢你！'
    },
    time: {
      hours: '1 小时 | {hours} 小时',
      minutes: '1 分钟 | {minutes} 分钟'
    },
    wizard: {
      choose_state: '选择状态',
      choose_appointment_type: '选择约会类型',
      no_available_appointment_types: '抱歉，我们目前没有可用的约会',
      no_available_appointment_types_by_state: '抱歉，我们目前在您所在的州没有预约',
      go_back: '回去',
      warning_message: '由于需求量大，最多需要 90 秒才能找到可用空间，请耐心等待。',
      choose_doctor: '选择一个提供商',
      no_available_providers: '抱歉，我们目前没有提供者参加此类约会',
      no_available_providers_by_state: '抱歉，我们目前没有提供者参加您所在州的此类约会',
      any_provider: '任意提供者',
      choose_date: '选择日期',
      choose_time: '选择时间',
      dropdown_default: '选择一个选项',
      complete_your_schedule: '完成你的日程安排',
      form_header_message: '几乎已经完成了！',
      form_field_referrer_email: '转诊医生的电子邮件',
      form_field_referrer_first_name: '转诊医生姓名',
      form_field_referrer_last_name: '转诊医生的姓氏',
      form_field_referrer_address: '地点',
      form_field_first_name: '名',
      form_field_last_name: '姓',
      form_field_cellphone: '手机',
      form_field_email: '电子邮件',
      form_field_ssn: '我没有有效的保险单（根据 CARES 法案提供者救济基金，我们将要求提供社会安全号码来验证您的保险状态）。',
      form_field_label_ssn: 'SSN *',
      form_field_visit_reason: '参观原因',
      form_field_placeholder_optional: '可选的',
      form_field_placeholder_optional_recommended: '可选，但强烈推荐',
      form_field_birthday: '出生日期',
      form_field_birthday_day: '日',
      form_field_birthday_month: '月',
      form_field_birthday_year: '年',
      form_field_legal_sex: '性',
      form_field_legal_sex_male: '男',
      form_field_legal_sex_female: '女',
      form_field_legal_sex_other: '其他',
      form_field_address: '地址',
      form_field_address_placeholder: '街道和门牌号',
      form_field_zip_code: '邮政编码',
      form_field_city: '城市',
      form_field_state: '州',
      form_field_state_warning: '我们目前仅向所列州的患者提供服务。',
      form_field_image: '上传您的保险卡的照片',
      form_field_card: '选择您首选项',
      form_field_insurance: '我有保险',
      form_field_image_one: '正面',
      form_field_image_two: '后退',
      form_field_image_error_one: '图像尺寸必须等于或小于10MB。',
      form_field_image_error_two: '文件格式不正确。 （.png，.jpg和.jpeg）。',
      form_field_image_error_three: '选择一个图像。',
      form_field_insurance_company: '健康保险',
      form_field_insurance_group_number: '群组编号',
      form_field_insurance_id_number: '会员编号',
      form_field_insurance_plan_type: '计划类型',
      form_label_terms_and_conditions_message: '我同意',
      form_label_terms_and_conditions_link: '条款及细则',
      form_label_terms_and_conditions_telehealth_link: '远程医疗知情同意',
      form_label_consent_to_text: '您是否同意通过短信接收有关您预约的详细信息，包括确认、重新安排和必要的付款说明方面的帮助？',
      form_submit_button: '安排会面',
      confirmed_message: '确认预约',
      details_message: '详情',
      reserved_message: '预订的',
      invitation_sent_message: '邀请已发送到您的电子邮件地址',
      invitation_sent_message_referrals: '已通过提供的电子邮件地址向您和您的患者发送了一封确认电子邮件',
      require_payment_information_message: '请添加您的付款信息。',
      no_charge_will_be_done_message: '此时不会收取任何费用',
      lead_time_require_payment_information_message: '需要付款信息才能预约。',
      lead_time_to_finish_message: '您有 {leadTime}的时间来完成信息。',
      payment_message: '您的约会已准备就绪！',
      suggest_payment_message: '如果您想在线支付，请点击下方',
      lead_time_payment_message: '您有 {leadTime}的时间来完成付款。',
      for_questions_message: '如有疑问，请致电办公室',
      complete_information: '资料齐全',
      continue_to_pay: '继续支付',
      pay_now: '现在付款',
      referrals_patient_information: '患者信息'
    },
    calendar: {
      day_name_sun: '星期日',
      day_name_mon: '星期一',
      day_name_tue: '星期二',
      day_name_wed: '星期三',
      day_name_thu: '星期四',
      day_name_fri: '星期五',
      day_name_sat: '星期六',
      month_name_1: '一月',
      month_name_2: '二月',
      month_name_3: '三月',
      month_name_4: '四月',
      month_name_5: '五月',
      month_name_6: '六月',
      month_name_7: '七月',
      month_name_8: '八月',
      month_name_9: '九月',
      month_name_10: '十月',
      month_name_11: '十一月',
      month_name_12: '十二月'
    },
    errors: {
      hashtag_not_found: '帐户ID不存在',
      server_error: '处理请求时出错',
      no_available_dates_title: '抱歉，目前没有可预约的时间。',
      no_available_dates_body: '请尝试其他提供商。',
      slots_not_found: '对不起，这一天的预约时间不可用。请再选择另一天',
      scheduling: {
        contacts_title: '哎呀！ 有一个小故障',
        contacts_body: '请验证您的详细信息并再试一次。 如果您仍然遇到问题，请随时通过 {officePhone} 联系办公室。',
        events_title: '糟糕，出了点问题',
        events_body: '让我们再试一次。 请确保您的信息正确。 如果问题仍然存在，您的办公室随时准备提供帮助。 请拨打 {officePhone} 联系他们。',
        failed_dependency_title: '很抱歉，我们无法安排您的约会。',
        failed_dependency_body: '请联系办公室安排您的约会 {officePhone}',
        gateway_timeout_title: '比平常花更长的时间',
        gateway_timeout_body: '我们仍在处理您的预约。 请拨打 {officePhone} 联系您的办公室，以验证您的预约状态。',
        general_title: '啊，事情没有按计划进行',
        general_body: '让我们再试一次。 请选择另一个时段并尝试再次安排您的预约。',
        slot_already_taken_title: '糟糕！ 其他人已经占据了这个空间。 别担心……还有更多选择！',
        slot_already_taken_body: '请选择其他时间。'
      }
    }
  }
}
