
import availableLanguage from '@/i18n/messages'
import { store } from '@/store'
import { Vue } from 'vue-class-component'

export default class LangSelector extends Vue {
  languages = availableLanguage

  get lang () {
    return store.state.lang
  }

  openSelector = false

  select (langId: string) {
    store.commit('changeLang', langId)
    this.$i18n.locale = langId
    this.openSelector = false
  }
}
