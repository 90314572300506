import TIMEZONES from '@/data/timezones'
import availableLanguages from '@/i18n/messages'
declare let gtag: any

export default class UtilService {
  static getUserLanguage () {
    let userLang: string = navigator.language || (navigator as any).userLanguage
    userLang = userLang.substring(0, 2)
    return Object.prototype.hasOwnProperty.call(availableLanguages, userLang) ? userLang : 'en'
  }

  static async getClientIP () {
    const res = await fetch('https://api.ipify.org?format=json')
    const data = await res.json()
    return data.ip
  }

  static getTimezones () {
    if (!TIMEZONES.includes(UtilService.getLocalTimezone())) {
      return [UtilService.getLocalTimezone()].concat(TIMEZONES)
    }
    else {
      return TIMEZONES
    }
  }

  static getLocalTimezone () {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  static getDaysInMonth (year: number, month: number) {
    return new Date(year, month, 0).getDate()
  }

  static getOriginalDate (dateString: string) {
    const stringDate = dateString.substring(0, 19) + '+0000'
    // Error with day light saving change. We should get the offset taking on count the date related. See https://github.com/Nimblr/web-scheduler/issues/58
    const offset = new Date(stringDate).getTimezoneOffset() / 60
    const date = new Date(Date.parse(stringDate))
    date.setHours(date.getHours() + offset)
    return date
  }

  static focusOnElement (id: string) {
    document.getElementById(id)!.focus()
  }

  static updateGAFlag (idGA: string, flag: boolean, action?: string, label?: string, iframe?: boolean): boolean {
    if (flag) return true
    if (idGA) {
      if (action) {
        if (iframe) {
          if (label) {
            // i --> Google Analytics id, a --> action, c --> category, l --> label
            window.top.postMessage({ i: idGA, a: action, c: 'holly', l: label }, '*')
          }
          else {
            window.top.postMessage({ i: idGA, a: action, c: 'holly' }, '*')
          }
        }
        else {
          gtag('config', idGA)
          if (label) {
            gtag('event', action, { event_category: 'holly', event_label: label })
          }
          else {
            gtag('event', action, { event_category: 'holly' })
          }
        }
      }
      return true
    }
    else {
      return false
    }
  }

  static async sleep (ms: number) : Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}
