<template>
  <div class="brand">{{ $t('global.powered_by') }}&nbsp;<a href="https://nimblr.ai/"  target="_blank">nimblr.ai</a>&nbsp;- v{{ appVersion }} </div>
</template>

<script>
import { Vue } from 'vue-class-component'
import { version } from '../../package'
export default class Brand extends Vue {
  appVersion = version
}
</script>
