
import { Options, Vue } from 'vue-class-component'
import Header from '@/components/Header.vue' // @ is an alias to /src
import Footer from '@/components/Footer.vue'
import UtilService from '@/services/UtilService'
import { store } from './store'

@Options({
  components: {
    Header,
    Footer
  }
})
export default class App extends Vue {
  mounted () {
    store.commit('changeLang', UtilService.getUserLanguage())
    store.commit('changeTimezone', UtilService.getLocalTimezone())
  }
}
