
import { Vue, Options } from 'vue-class-component'
import Brand from '@/components/Brand.vue'
import LangSelector from '@/components/LangSelector.vue'

@Options({
  props: {
    currentStep: {
      type: Number,
      default: 0
    }
  },
  components: {
    Brand,
    LangSelector
  }
})
export default class Footer extends Vue {
}
