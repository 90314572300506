<template>
  <div class="lang-selector">
    <span @click="openSelector = !openSelector">{{ languages[lang].native_name_of_the_language }} &#9662;</span>
    <ul v-if="openSelector">
      <template v-for="(language, key) in languages" :key="language.key">
        <li :class="{selected : lang === key}" @click="select(key)">{{ language.native_name_of_the_language }}</li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts">
import availableLanguage from '@/i18n/messages'
import { store } from '@/store'
import { Vue } from 'vue-class-component'

export default class LangSelector extends Vue {
  languages = availableLanguage

  get lang () {
    return store.state.lang
  }

  openSelector = false

  select (langId: string) {
    store.commit('changeLang', langId)
    this.$i18n.locale = langId
    this.openSelector = false
  }
}
</script>

<style lang="scss" scoped>
.lang-selector{
    cursor: pointer;
    position: relative;
    padding: 5px 20px;
    width:fit-content;
    margin: 0 auto;
    span {
      display: block;
      text-align: center;
    }
    ul {
      position: absolute;
      left: 50%;
      bottom: 15px;
      transform: translateX(-50%);
      padding: 5px;
      max-height: 105px;
      overflow-y: auto;
      border: 1px solid #ccc;
      list-style: none;
      background-color: var(--background-color);
      // Configure dark schema
      @media (prefers-color-scheme: dark) {
        background-color: #204060;
      }
      li {
        cursor: default;
        padding: 4px 5px;
        font-weight: bold;
        &:not(.selected) {
          color: var(--text-color);
          @media (prefers-color-scheme: dark) {
            color: #FFFFFF;
          }
          &:hover {
            cursor: pointer;
          background-color: var(--hover-selected-background-color);
          color: var(--hover-selected-text-color);
          opacity: 0.5;
          // Configure dark schema
          @media (prefers-color-scheme: dark) {
            color: #123456;
            background-color: #FFFFFF;
          }
          }
        }
        &.selected {
          font-weight: bolder;
          background-color: var(--hover-selected-background-color);
          color: var(--hover-selected-text-color);
          opacity: 1;
          // Configure dark schema
          @media (prefers-color-scheme: dark) {
            color: #123456;
            background-color: #FFFFFF;
          }
          &:hover {
            cursor: default;
          }
        }
      }
    }
  }
</style>
