<template>
  <header class="header" :class="computedHederClass">
    <!-- Version of the WS (Secret information)-->
    <div style="display: none">v{{ appVersion }}</div>
    <!-- Logo and location name show only on desktop (hidden on mobile) and on appointment selection and confirm views on mobile-->
    <div v-if="logo" class="header__logo" :class="computedLogoClass"  :style="computedLogoStyle"></div>
    <!-- Header Content -->
    <div class="header__texts" :class="computedTextsContainerClass">
      <div class="header__title-group">
        <h1 class="header__title" :class="computedTitleClass">{{ title || $t('global.default_title') }}</h1>
        <h2 class="header__subtitle" :class="computedSubtitleClass">{{ subtitle }}</h2>
      </div>
      <!-- Slot to insert summary for desktop view -->
      <slot></slot>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { version } from '../../package.json'

export default defineComponent({
  props: {
    logo: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    currentStep: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      appVersion: version,
      wideLogo: false
    }
  },
  computed: {
    computedHederClass (): Record<string, boolean> {
      const computedClass = {
        // On desktop the header should be horizontal if we already select an appointment (step > 1) and we are not in the confirm view (step != 6)
        'header--horizontal': this.currentStep > 1 && this.currentStep !== 6
      }
      return computedClass
    },
    computedLogoClass (): Record<string, boolean> {
      const computedClass = {
        'header__logo--wide': this.wideLogo,
        'header__logo--large': this.currentStep === 5,
        'header__logo--large-wide': this.currentStep === 5 && this.wideLogo,
        // On mobile the logo should be hidden if you already select an appointment (step > 1) and we are not in the confirm view (step != 6)
        'hidden-mobile': this.currentStep > 1 && this.currentStep !== 6
      }
      return computedClass
    },
    computedTitleClass (): Record<string, boolean> {
      const computedClass = {
        // On desktop the title should be small if we already select an appointment (step > 1) and we are not in the confirm view (step != 6)
        'header__title--small': this.currentStep > 1 && this.currentStep !== 6
      }
      return computedClass
    },
    computedLogoStyle (): Record<string, string> {
      const computedStyle = {
        'background-image': `url(${this.logo})`
      }
      return computedStyle
    },
    computedTextsContainerClass (): Record<string, boolean> {
      const computedClass = {
        // On desktop the texts container should align the text to the start if we already select an appointment (step > 1) and if we have a logo (Boolean(this.logo))
        'header__texts--start-text': this.currentStep > 1 && Boolean(this.logo)
      }
      return computedClass
    },
    computedSubtitleClass (): Record<string, boolean> {
      const computedClass = {
        // On mobile the subtitle only should be shown on form view
        'header__subtitle--shown-on-mobile': this.currentStep === 5,
        // On desktop the subtitle only should be shown once an appointment is selected (step > 1) and before the appointment is schedule (step < 6)
        'header__subtitle--shown-on-desktop': this.currentStep > 1 && (this.currentStep < 6 || this.currentStep === 7)
      }
      return computedClass
    }
  },
  mounted () {
    if (this.logo) {
      const img = new Image()
      img.src = this.logo
      img.onload = () => {
        if (img.width > img.height) {
          this.wideLogo = true
        }
      }
    }
  }
})
</script>
