import { createStore } from 'vuex'

export const store = createStore({
  state: {
    lang: 'en',
    customTexts: {},
    timezone: '',
    officePhone: '',
    colors: {
      backgroundColor: '#FFFFFF',
      borderColor: '#1C314F',
      textColor: '#1C314F',
      hoverSelectedBackgroundColor: '',
      hoverSelectedBorderColor: '',
      hoverSelectedTextColor: ''
    },
    loadingSlots: false,
    formErrors: []
  },
  mutations: {
    changeLang (state, lang) {
      state.lang = lang
    },
    setCustomTexts (state, texts) {
      state.customTexts = texts
    },
    changeTimezone (state, timezone) {
      state.timezone = timezone
    },
    changeOfficePhone (state, officePhone) {
      state.officePhone = officePhone
    },
    changeColors (state, colors) {
      state.colors = colors
    },
    changeLoadingSlots (state, flag) {
      state.loadingSlots = flag
    },
    changeFormErrors (state, newFormErrors) {
      state.formErrors = newFormErrors
    }
  }
})
