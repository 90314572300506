import UtilService from '@/services/UtilService'
import { createI18n } from 'vue-i18n'
import messages from './messages'

let userLang: string = navigator.language || (navigator as any).userLanguage
userLang = userLang.substring(0, 2)

const i18n = createI18n({
  locale: UtilService.getUserLanguage(),
  fallbackLocale: 'en',
  messages
})

export default i18n
